<template>
  <div class="animated fadeIn">
    <v-col cols="12">
      <person-tables 
      :get="'/clinic/hr/servants'" 
      :job="'servant'" 
      :faJob="'كاركنان خدمات'"
      :Fields="Fields"
      :excelFields="excelFields"
      :new="'newEmployee/'" 
      :edit="'editServant/'" 
      :title="'كاركنان خدمات'"/>
    </v-col>
  </div>
</template>
<script>
const PersonTables = () => import("@/components/hr/PersonTables");

export default {
  components: {
    PersonTables
  },
  data(){
    return{
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "status", label: "وضعیت " },
        { key: "operation", label: "مدیریت " },
      ],   
      excelFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "status", label: "وضعیت " },
      ],      
    }
  }
};
</script>
